import React,{ Component } from "react";
import './menu.scss';
import axios from "axios";

export default class Menu extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

        // 设置当前页码，默认为第一页
        this.state = {
            menu: [],
            openId: -1
        }
    }
    componentDidMount(){
        this.getAllBlogMenu();
    }
    getAllBlogMenu() {
        axios.get('/admin/blog/menu')
            .then(res => {
                const data = res.data;
                this.setState({
                    menu: data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }
    getBlogById(blogId) {
        console.log(blogId);
        this.props.cb(blogId)
    }
    showChildren(index) {
        console.log(index);
        if (index === this.state.openId) {
            index = -1;
        }
        this.setState({
            openId: index
        });
    }

    render(){
        return(
            <div className="menu_body">
                <div id="menu_box" className="nav">
                    <ul id="menu_list">
                        {
                            this.state.menu.map((item,index) => {
                                return (<li key={item.typeId} className={this.state.openId === index ? 'nav-item nav-show':'nav-item'}>
                                    <a onClick={this.showChildren.bind(this, index)}><i className='glyphicon glyphicon-ice-lolly blog_type_li'/>
                                        <span>{item.typeName + "(" + item.titleList.length + ")"}</span>
                                        <i className='my-icon nav-more'/>
                                    </a>
                                    <ul>
                                        {
                                            item.titleList.map((li_item) => {
                                                return (
                                                    <li key={li_item.blogId} onClick={this.getBlogById.bind(this,li_item.blogId)}>
                                                        <a className='blog_title'>
                                                            <span>{li_item.blogTitle}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>);
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}