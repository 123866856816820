import './index.scss';
import {Component} from "react";
import axios from 'axios';
import Pagination from "../../../components/pagination";
import {Link} from "react-router-dom";

class Blog extends Component {
    constructor(props) {
        super(props);
        console.log("Blog");
        this.state = {
            blog_type: '',
            type_list: [],
            blog_data: {},
            blog_list: [],
            blog_pages: {},
            pageCurr: 1,
            totalPage: 1,
            box_width: 0,
            param:{
                blogType: 3
            }
        }
    }
    componentDidMount(){
        let bw = Math.floor(window.innerWidth / 340) * 340;
        this.setState({
            box_width: bw > 1360 ? 1360 : bw
        });
        console.log(this.state.box_width);
        //监听窗口大小改变
        window.addEventListener('resize', this.handleResize.bind(this));
        this.getAllBlogTag();
        this.getBlogByPage(1,{});
    }

    handleResize = e => {
        let e_width = e.target.innerWidth;
        let bw = Math.floor(e_width / 340) * 340;
        this.setState({
            box_width: bw > 1360 ? 1360 : bw
        });
        console.log('浏览器窗口大小改变事件', this.state.box_width);
    };

    getAllBlogTag() {
        axios.get('/api/type/all')
            .then(res => {
                const data = res.data;
                this.setState({
                    type_list: data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getBlogByPage(page,param) {
        console.log(param);
        if (param !== {}) {
            this.setState({
                blog_type: param.blogType
            })
        }
        axios.get('/api/blog/all',{
            params: {
                "page":page,
                "pageSize":12,
                "blogType": param==={}?'':param.blogType
            }
        }).then(res => {
                const data = res.data;
                this.setState({
                    blog_data: data,
                    blog_list: data.list,
                    pageCurr: data.pageNum,
                    totalPage: data.pages,
                    param: param
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {

        return (
            <div id="blog" data-component = "Blog">
                <div id="blog_top">
                    {/*页头工具栏*/}
                    <div className="blog-list-header">
                        <div className="head_center">
                            <div className="head_img">
                                <a href="/"><img alt="" className="is-rounded" src="/img/favicon.ico" /></a>
                            </div>
                            <div>
                                {/*<Link to={"/header/editor"}>写博客</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
                

                <div id="blog_list_box">
                    <div className="sidebar-header">
                        <div className="my_blog">
                            <div>
                                <a >最新</a>
                                <div className="un-link"/>
                            </div>
                            <div>
                                <Link to="/person">最热</Link>
                                <div className="un-link"/>
                            </div>
                            <div>
                                <Link to="/person">编程</Link>
                                <div className="un-link"/>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar">
                        <div className="sidebar-ul">
                            {
                                this.state.type_list.map((item) => {
                                    return (<li key={item.typeId}
                                                onClick={this.getBlogByPage.bind(this, 1, {"blogType": item.typeId})}
                                                className={item.typeId === this.state.blog_type ? "tag tag_active" : "tag"}>{item.typeName}</li>)
                                })
                            }
                        </div>
                    </div>
                    {/*博客预览列表*/}
                    <div className="blog_page">
                        <div className="blog_box" style={{width:this.state.box_width}}>
                            {
                                this.state.blog_list.map((item) => {
                                    return (
                                        <Link to={{pathname: '/user/content/' + item.blogUuid}}>
                                            <div className='blog_list' key={item.blogUuid} >
                                                <div className='blog_img'>
                                                    <img src={'/img/blog.jpg'} alt=""/>
                                                </div>
                                                <div className='blog_title'>
                                                    {item.blogTitle}
                                                </div>

                                                <div className='blog_tip_box' >
                                                    <div className='blog_tip'>
                                                        <b>{item.publishDate}</b>
                                                    </div>
                                                    <div className='blog_tip'>
                                                        <b>{item.pageView}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/*分页条*/}
                    {
                        this.state.blog_list.length > 0 ?
                            <div className="page-box">
                                <article>
                                    <Pagination config={{
                                        pageCurr: this.state.pageCurr,
                                        totalPage: this.state.totalPage
                                    }}
                                     param={this.state.param}
                                     cb={this.getBlogByPage.bind(this)}
                                    />
                                </article>
                            </div>
                            : null
                    }
                </div>
            </div>
        );
    }
}

export default Blog;
