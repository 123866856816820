import React, {Component} from 'react';
import MdEditor, {Plugins} from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import './index.scss'
import '../../../components/menu/menu.scss'
import './editormd.scss'
import MarkdownIt from "markdown-it";
import axios from "axios";
import Menu from "../../../components/menu";
import Modal from "../../../components/modal/BlogModal";
import Toast from "../../../components/alert/Toast";

const PLUGINS = undefined;
// const PLUGINS = ['manage', 'divider', 'image', 'divider', 'font-bold', 'full-screen'];

/*MdEditor.use(Plugins.AutoResize, {
  min: 750,
  max: 800
});*/

MdEditor.use(Plugins.TabInsert, {
    tabMapValue: 1, // note that 1 means a '\t' instead of ' '.
});
const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true
});

class Editor extends Component {
    mdEditor = undefined;

    constructor(props) {
        super(props);
        this.renderHTML = this.renderHTML.bind(this);
        this.state = {
            content: "",
            html:"",
            blogId: "",
            blog:{
                blogId: null,
                blogTitle: "",
                blogType: null,
                blogContent: "",
                blogHtml: ""
            },
            is_active: false,
            isOpen: false,
            blogTypes: {},
            typeMap: new Map()
        };
    }
    componentDidMount(){
        this.getAllBlogMenu();
        this.selectAllType();
    }

    selectAllType(){
        axios.get('/admin/type')
            .then(res => {
                const data = res.data;
                const map = new Map(data.map(item => [item.typeId, item.typeName]));
                this.setState({
                    blogTypes: data,
                    typeMap: map
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    setIsOpen(falg) {
        this.setState({
            isOpen: falg
        })
    }
    showChildren(index) {
        console.log(index);
        if (index === this.state.openId) {
            index = -1;
        }
        this.setState({
            openId: index
        });
    }
    getAllBlogMenu() {
        axios.get('/admin/blog/menu')
            .then(res => {
                const data = res.data;
                this.setState({
                    menu: data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    callBackSelectBlog(blogId) {
        this.getBlogById(blogId);
    }

    callBackSendBlog(flag) {
        if (flag === "SUCCESS") {
            this.getAllBlogMenu();
            this.newBlog();
        }
    }
    getBlogById(blogId) {
        console.log(blogId);
        axios.get('/admin/blog/' + blogId)
            .then(res => {
                const data = res.data;
                this.setState({
                    blog: data
                });
                this.setState({
                    blog: {
                        ...this.state.blog,
                        blogHtml: this.mdEditor.getHtmlValue()
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    saveBlog(){
        if (this.state.blog.blogTitle === '') {
            Toast.info('文章标题不能为空！', 2000);
        }else if (this.state.blog.blogContent === '') {
            Toast.info('文章内容不能为空！', 2000);
        }else {
            this.child.open();
        }
    }

    newBlog(){
        this.setState({
            blog: {
                blogId: null,
                blogTitle: "",
                blogType: null,
                blogContent: "",
            }
        });
    }

    handleEditorChange = (it, event) => {
        this.setState({
            blog: {
                ...this.state.blog,
                blogContent: it.text,
                blogHtml: this.mdEditor.getHtmlValue()
            }

        });
    };

    handleImageUpload = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = data => {
                resolve(data.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    onCustomImageUpload = (event) => {
        console.log('onCustomImageUpload', event);
        return new Promise((resolve, reject) => {
            const result = window.prompt('Please enter image url here...');
            resolve({ url: result });
            // custom confirm message pseudo code
            // YourCustomDialog.open(() => {
            //   setTimeout(() => {
            //     // setTimeout 模拟oss异步上传图片
            //     // 当oss异步上传获取图片地址后，执行calback回调（参数为imageUrl字符串），即可将图片地址写入markdown
            //     const url = 'https://avatars0.githubusercontent.com/u/21263805?s=80&v=4'
            //     resolve({url: url, name: 'pic'})
            //   }, 1000)
            // })
        });
    };

    handleGetMdValue = () => {
        if (this.mdEditor) {
            alert(this.mdEditor.getMdValue());
        }
    };

    handleGetHtmlValue = () => {
        if (this.mdEditor) {
            alert(this.mdEditor.getHtmlValue());
        }
    };

    handleSetValue = () => {
        const text = window.prompt('Content');
        this.setState({
            blog: {
                ...this.state.blog,
                blogContent: text,
            }
        });
    };

    renderHTML(text) {
        const html = mdParser.render(text);
        this.setState({
            html: html,
        });
        return html;
    }

    maxLength = 50;

    handleChange = (event) => {
        if (event.target.value.length <= this.maxLength) {
            let newBlog=Object.assign(this.state.blog,{["blogTitle"]: event.target.value});
            this.setState({
                blog: newBlog
            });
        }
    };

    handleOptionClick(option) {
        let newBlog=Object.assign(this.state.blog,{["blogType"]: option});
        this.setState({
            blog: newBlog
        });
    }

    render() {
        return (
            <div data-component="Editor">
                <div id="main_box">
                    <Menu cb={this.callBackSelectBlog.bind(this)}/>
                    <div id="markdown" className="demo-wrap">
                        <Modal blog={this.state.blog} onRef={(ref) => {
                            this.child = ref
                        }} cb={this.callBackSendBlog.bind(this)}/>
                        <div className="editor-form">
                            <div className="editor-title">
                                <div className="input-box">
                                    <input type="text" className="title-input" value={this.state.blog.blogTitle}
                                           placeholder="文章标题" maxLength={this.maxLength} onChange={this.handleChange}/>
                                    <span className="word-count">{this.state.blog.blogTitle ? this.state.blog.blogTitle.length : 0 }/{this.maxLength}</span>
                                </div>
                            </div>
                            <div className="dropdown">
                                <div className="dropdown-selected" onClick={() => this.setIsOpen(!this.state.isOpen)}>
                                    {this.state.blog.blogType ? this.state.typeMap.get(this.state.blog.blogType) : "请选择文章类型"}
                                    <i className={`arrow ${this.state.isOpen ? 'up' : 'down'}`}/>
                                </div>
                                {this.state.isOpen && (
                                    <div className="dropdown-options">
                                        {this.state.blogTypes.map((option) => (
                                            <div
                                                key={option.typeId}
                                                className={`dropdown-option ${option.typeId === this.state.blog.blogType ? 'selected' : ''}`}
                                                onClick={() => this.handleOptionClick(option.typeId)}
                                            >
                                                {option.typeName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="editor-save" style={{textAlign: 'right'}} data-component="Bulma">
                                <button type="button" className="button is-success" onClick={this.newBlog.bind(this)}
                                        style={{margin: '10px 10px 10px 0'}}>新文章
                                </button>
                                <button type="button" className="button is-success" onClick={this.saveBlog.bind(this)}
                                        style={{margin: '10px 10px 10px 0'}}>发布
                                </button>
                            </div>
                        </div>

                        <div className="editor-wrap" data-component="EditorMd">
                            <MdEditor
                                ref={node => (this.mdEditor = node || undefined)}
                                value={this.state.blog.blogContent}
                                style={{height: '83vh', width: '100%'}}
                                renderHTML={this.renderHTML}
                                plugins={PLUGINS}
                                config={{
                                    view: {
                                        menu: true,
                                        md: true,
                                        html: true,
                                        fullScreen: true,
                                        hideMenu: true,
                                    },
                                    table: {
                                        maxRow: 5,
                                        maxCol: 6,
                                    },
                                    imageUrl: 'https://octodex.github.com/images/minion.png',
                                    syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
                                }}
                                onChange={this.handleEditorChange}
                                onImageUpload={this.handleImageUpload}
                                onFocus={e => console.log('focus', e)}
                                onBlur={e => console.log('blur', e)}
                                onCustomImageUpload={this.onCustomImageUpload}
                            />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Editor;