import React, {Component} from "react";
import "./index.scss"


class FileList extends Component{
    constructor(props) {
        super(props);
        console.log("Files");
        this.state = {
            files: [],
            confirm: {
                isConfirmOpen: false,
                fileToDelete: null,
                fileName: null
            }
        }
    }
    componentDidMount(){
        this.getAllFileList();
    }

    getAllFileList() {
        //获取文件列表
        fetch('/admin/file/file-list')
            .then(response => response.json())
            .then(data =>  this.setState({files: data}))
            .catch(error => console.error(error));
    }

    //删除文件
     deleteFile(index){
        const updatedFiles = this.state.files;
        updatedFiles.splice(index, 1);
        this.setState({
            files: updatedFiles
        })
    }

    handleDeleteClick(id){
        const name = this.state.files[id].fileName;
        this.setState({
            confirm: {
                isConfirmOpen: true,
                fileToDelete: id,
                fileName: name
            }
        });
    }

    handleConfirmClose(){
        this.setState({
            confirm: {
                isConfirmOpen: false,
                fileToDelete: null,
                fileName: null
            }
        });
    }

    handleConfirmOk(){
        this.deleteFile(this.state.confirm.fileToDelete);
        this.setState({
            confirm: {
                isConfirmOpen: false,
                fileToDelete: null,
                fileName: null
            }
        });
    };
    render() {
        return (
            <div className="fileList-container">
                <h2>文件列表</h2>
                {this.state.files.map((file, index) => (
                    <div className="fileList-item" key={index}>
                        <div className="fileList-item-info">
                    <span className="fileList-item-name">
                      <a href={file.fileLink} download>{file.fileName}</a>
                    </span>
                            <span className="fileList-item-size">{file.fileSize}</span>
                        </div>
                        <div className="fileList-item-delete-container">
                            <button className="fileList-item-delete" onClick={() => this.handleDeleteClick(index)}>删除
                            </button>
                        </div>
                    </div>
                ))}
                {this.state.confirm.isConfirmOpen && (
                    <div className="confirm-mask">
                        <div className="confirm-box">
                            <p>确认删除（{this.state.confirm.fileName}）</p>
                            <div>
                                <button className="cancel-btn" onClick={() => this.handleConfirmClose()}>取消</button>
                                <button className="ok-btn" onClick={() => this.handleConfirmOk()}>确定</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default FileList;
