import React, {Component} from "react";
import './index.scss'
import FileUploader from "../../../components/upload";
import FileList from "../../../components/upload/fileList";

class Files extends Component {
    constructor(props) {
        super(props);
        console.log("Files");
        this.state = {
            fileList: []
        }
    }
    componentDidMount(){

    }

    render() {
        return (
            <div data-component="File">
                <div id="main_box">
                    <div className="file_list" id="file_list">
                        <FileList/>
                    </div>
                    <div className="file_upload">
                        <FileUploader/>
                    </div>
                </div>
            </div>
        );
    }
}
export default Files;