import './index.scss';
import React, {Component} from "react";
import {Link} from "react-router-dom";
import Editor from "../editor";
import Files from "../files";
class Manage extends Component {
    constructor(props) {
        super(props);
        console.log("Manage");
        this.state = {
            menu: "editor"
        }
    }
    componentWillMount(){
        console.log(this.props.match.params.tab);
        this.setState({
            menu: this.props.match.params.tab != null ? this.props.match.params.tab : "user"
        })
    }

    setMenuTab(tab) {
        this.setState({
            menu: tab
        })
    }

    render() {
        return (
            <div className="Admin" data-component="Manage">
                <div className="admin_header">
                    <ul className="menu-ul">
                        {this.state.menu === "editor" ?
                            <Link className={"menu-item-active active"}>写博客</Link> :
                            <Link className={"menu-item"} to="/manage/editor" onClick={this.setMenuTab.bind(this, "editor")}>写博客</Link>
                        }
                        {this.state.menu === "files" ?
                            <Link className={"menu-item-active active"}>文件管理</Link> :
                            <Link className={"menu-item"} to="/manage/files" onClick={this.setMenuTab.bind(this, "files")}>文件管理</Link>
                        }
                        {this.state.menu === "img" ?
                            <Link className={"menu-item-active active"}>资源管理</Link> :
                            <Link className={"menu-item"} to="/manage/img" onClick={this.setMenuTab.bind(this, "img")}>资源管理</Link>
                        }
                        {this.state.menu === "user" ?
                            <Link className={"menu-item-active active"}>个人中心</Link> :
                            <Link className={"menu-item"} to="/manage/user" onClick={this.setMenuTab.bind(this, "user")}>个人中心</Link>
                        }
                    </ul>

                    <div id="admin_wrap">
                        <a href="/"><img src="/img/favicon.ico" draggable="false"/></a>
                    </div>
                </div>
                {this.state.menu === "editor" ?
                    <Editor/> : null
                }
                {this.state.menu === "files" ?
                    <Files/> : null
                }
                {this.state.menu === "img" ?
                    "<Img/>" : null
                }
                {this.state.menu === "user" ?
                    "<User/>" : null
                }
            </div>
        );
    }
}

export default Manage;
