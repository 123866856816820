import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Home from './containers/facade/home';
import APP from './containers/facade/app';
import Person from "./containers/facade/person";
import Blog from "./containers/facade/blog";
import Content from "./containers/facade/content";
import Manage from "./containers/admin/manage";

class Routers extends React.Component {
    render(){
        return (
            <Router>
                <Route path="/" component={Home} exact/>
                <Route path="/app" component={APP} exact/>
                <Route path="/person" component={Person} exact/>
                <Route path="/user/blog" component={Blog} exact/>
                <Route path="/user/content/:blogId" component={Content} exact/>
                <Route path="/manage/:tab" component={Manage} exact/>
                <Route path="/manage" component={Manage} exact/>
            </Router>
        );
    }
}

export default Routers;
