import './index.scss';
import {Component} from "react";
import axios from "axios";
import React from 'react';
import './editormd.scss';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogId: "",
            blog: {},
            comment: "",
            commentList: []
        }

    }
    componentWillMount(){
        console.log(this.props.location)//传递过来的所有参数
        console.log(this.props.match.params.blogId)//传递过来的所有参数
        this.setState({
            blogId: this.props.match.params.blogId
        });
        this.selectBlogById(this.props.match.params.blogId);
        this.selectCommentById(this.props.match.params.blogId);
    }
    selectBlogById(blogId) {
        console.log(blogId);
        axios.get('/api/blog/blogId/'+blogId).then(res => {
            const data = res.data;
            this.setState({
                blog: data
            });
            document.title = data.blogTitle;
        })
        .catch(err => {
            console.log(err);
        });
    }
    /*根据blogId查询博客的评论内容*/
    selectCommentById(blogId) {
        axios.get('/api/blog/comment/'+blogId).then(res => {
            const data = res.data;
            console.log(data);
            this.setState({
                commentList: data
            })
        })
        .catch(err => {
            console.log(err);
        });
    }
    // 发表评论
    addComment() {
        if(this.state.comment !==""){
            axios.post('/api/blog/comment',
                {
                    "blogId":this.state.blogId,
                    "commentData":this.state.comment
                },
                {timeout: 1000}).then(res => {
                this.setState({
                    comment: ""
                });
                this.selectCommentById(this.state.blogId);
            }).catch(err => {
                console.log(err);
                alert("评论失败！");
            });
        }else {
            alert("评论内容不能为空！")
        }
    }

    handleChange(event) {
        this.setState({
            comment: event.target.value
        });
    }
    render() {
        const blog = this.state.blog;
        return (
            <div data-component="Content">
                <div className="nav-box">
                    <div className="header-logo">
                        <a href="/"><img src="/img/logoko.png"/></a>
                    </div>
                    <div className="menu-box">
                        <a className="menu-item active" href="/user/blog" title="">BLOG HOME</a>
                    </div>
                </div>
                <div className="container blog_show">
                    <div className="row">
                        <div id="blog_title">
                            <div className="head_img">
                                <img src="/img/favicon.ico"/>
                            </div>
                            <div className="head_title">
                                <div className="content_title">{blog.blogTitle}</div>
                                <div id='blog_tag'>
                                    <span>作者 : <strong>{blog.userName}</strong></span>
                                    <span>编辑于 : <strong>{blog.blogDate}</strong></span>
                                    <span>阅读 : <strong>{blog.pageView}</strong></span>
                                    <span>标签 : <strong>{blog.typeName}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div id="blog_body" className="markdown-body editormd-html-preview">
                            <div dangerouslySetInnerHTML={{__html: blog.blogHtml}}/>
                        </div>
                    </div>
                    {/* {
                        this.state.blog.blogUuid ?
                            <div className="row">
                                <div className="comment">
                                    <h3>评论区</h3>
                                    <textarea className="form-control" value={this.state.comment} style={{height: 100}}
                                              type="text" onChange={this.handleChange.bind(this)}
                                              placeholder="智慧如你，不想发表一点想法咩~"/>
                                    <div style={{textAlign: 'right'}} data-component="Bulma">
                                        <button onClick={this.addComment.bind(this)} type="button"
                                                className="button is-success"
                                                style={{margin: '10px 50px'}}>发表评论
                                        </button>
                                    </div>
                                </div>
                                <div className="comment">
                                    <h3>评论列表</h3>
                                    <div id="comment_list">
                                        {this.state.commentList.length > 0 ?
                                            this.state.commentList.map((item) => {
                                                return (
                                                    <div className='comment_item'>
                                                        <div className='head_img'>
                                                            <img src='/img/favicon.ico'/>
                                                        </div>
                                                        <div className='comment_box'>
                                                            <div className='nickname'>小黄人</div>
                                                            <div><p>{item.commentData}</p></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{textAlign: 'center'}}>
                                                <img style={{height: 80, width: 160}} src="/img/icon_comment.png"/>
                                                <p><span style={{fontSize: 12}}>还没有评论咩~</span></p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div id="wrapper">
                                    <span className="line"/>
                                    <span className="content">我也是有底线的</span>
                                    <span className="line"/>
                                </div>
                            </div>
                            : null
                    } */}
                </div>
            </div>
        );
    }
}

export default Content;
