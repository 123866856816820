import React,{ Component } from "react";
import './index.css';

export default class Pagination extends Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props)
        console.log(props);
        // 设置当前页码，默认为第一页
        this.state = {
            pageCurr:1,//当前页码
            groupCount:7,
            startPage:1,
            pageCount:10,//每页条数
        }
    }

    create(){
        const {
            totalPage,
        } = this.props.config;

        const {
            pageCurr,
            groupCount,
            startPage
        } = this.state;

        let pages = [];
        if( totalPage <= 10){
            pages.push(<li onClick = { this.goPrev.bind(this) } className = { this.state.pageCurr === 1? "no-more":"" } key={0}>上一页</li>)
            for(let i = 1;i <= totalPage; i++){
                // 点击页码时调用 go 方法，根据 state 判断是否应用 active 样式
                pages.push(<li onClick = { this.go.bind(this,i) } className = { pageCurr === i ? "page-active" : "" } key={i}>{i}</li>)
            }
            pages.push(<li onClick = { this.goNext.bind(this) } className = { this.state.pageCurr === totalPage? "no-more":"" } key={totalPage + 1}>下一页</li>)
        }else{
            pages.push(<li className = { this.state.pageCurr === 1? "no-more":"" } key={ 0 } onClick = { this.goPrev.bind(this) }>上一页</li>)
            for(let i = startPage;i < groupCount + startPage;i ++){
                if(i <= totalPage - 2){
                    pages.push(<li className = { this.state.pageCurr === i? "page-active":""} key={i} onClick = { this.go.bind(this,i) }>{i}</li>)
                }
            }

            // 分页中间的省略号
            if(totalPage - startPage >= 9){
                pages.push(<li className = {"ellipsis"} key={ -1 }>···</li>)
            }
            // 倒数第一、第二页
            pages.push(<li className = { this.state.pageCurr === totalPage -1 ? "page-active":""} key={ totalPage - 1 } onClick = { this.go.bind(this,totalPage - 1) }>{ totalPage -1 }</li>)
            pages.push(<li className = { this.state.pageCurr === totalPage ? "page-active":""} key={ totalPage } onClick = { this.go.bind(this,totalPage) }>{ totalPage }</li>)

            // 下一页
            pages.push(<li className = { this.state.pageCurr === totalPage ? "no-more":"" } key={ totalPage + 1 } onClick = { this.goNext.bind(this) }>下一页</li>)
        }
        return pages;
    }

    // 更新 state
    go(pageCurr,reset = false){
        const {
            groupCount
        } = this.state;

        const {
            totalPage
        } = this.props.config

        this.setState({
            pageCurr
        });

        // 处理上一页的情况
        if(pageCurr % groupCount === 1){
            this.setState({
                startPage:pageCurr
            })
        }

        // 处理下一页的情况
        if(pageCurr % groupCount === 0){
            this.setState({
                startPage:pageCurr - groupCount + 1
            })
        }

        // 如果点击最后两位的时候
        if(totalPage - pageCurr < 2){
            this.setState({
                startPage:totalPage - groupCount,
            })
        }
        setTimeout(()=>{
            this.props.cb(this.state.pageCurr,this.props.param)
        });
    }
    // 页面向前
    goPrev(){
        let {
            pageCurr,
        } = this.state;

        if(--pageCurr === 0){
            return;
        }

        this.go(pageCurr)
    }
    // 页面向后
    goNext(){
        let {
            pageCurr
        } = this.state;

        const {
            totalPage,
        } = this.props.config;

        if(++pageCurr > totalPage){
            return;
        }

        this.go(pageCurr)
    }


    render(){
        const Pages = this.create.bind(this)();
        return(
            <div className = "page-main ">
                <ul className = "page">
                    { Pages }
                </ul>
            </div>
        );
    }
}
