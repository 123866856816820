import React from 'react';
import ReactDOM from 'react-dom';
import Routers from './router';
import './index.css'
import './bulma.scss'

ReactDOM.render(
  <React.StrictMode>
      <Routers/>
  </React.StrictMode>,
  document.getElementById('root')
);

