import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import './index.scss';

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 MB';
    const k = 1024; // or 1000
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const fileSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    const unit = sizes[i];
    return `${fileSize} ${unit}`;
}

function FileUploader() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileInfo, setFileInfo] = useState(null);

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setFileInfo({
            name: file.name,
            size: file.size,
        });
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
        setFileInfo({
            name: file.name,
            size: file.size,
        });
    };

    const handleFileUpload = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        // 这里可以根据具体请求方式修改
        fetch("/admin/file/upload", {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                console.log('上传成功!');
            })
            .catch((error) => {
                console.error('上传失败:', error);
            });
    };

    return (
        <div
            className="file-upload-container-large"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <form onSubmit={handleFileUpload}>
                <h3 className="file-upload-title">
                    <FaUpload />
                    <span>文件上传</span>
                </h3>
                <div className="file-upload-drag-area">
                    <label htmlFor="file-upload" className="file-upload-label">
                        <FaUpload />
                        {selectedFile ? (
                            <>
                                <p>
                                    <strong>{fileInfo.name}</strong> ({formatBytes(fileInfo.size)})
                                </p>
                            </>
                        ) : (
                            '选择文件或拖拽到这里'
                        )}
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif,.pdf,.zip,.rar,.7z,.doc,.docx,.xlsx,.xls"
                        className="file-upload-input"
                        onChange={handleFileSelect}
                    />
                </div>
                {selectedFile ? (
                    <button type="submit" className="file-upload-button">
                        上传
                    </button>
                ) : null}
                {fileInfo ? (
                    <ul className="file-info-list">
                        <li>文件名: {fileInfo.name}</li>
                        <li>文件大小: {formatBytes(fileInfo.size)}</li>
                    </ul>
                ) : null}
            </form>
        </div>
    );
}

export default FileUploader;
