import React,{ Component } from "react";
import axios from "axios";
import Toast from "../alert/Toast";

export default class BlogModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_active: false,
            types: [],
            blog: {}
        }
    }

    componentDidMount() {
        this.props.onRef(this);    // 调用父组件传入的函数，把自身赋给父组件
        this.selectAllType();
    }

    open() {
        this.setState({
            is_active: true,
            blog: this.props.blog
        });
    }

    close() {
        this.setState({
            is_active: false
        })
    }

    sendBlog() {
        let  blog = this.state.blog;
        console.log(blog);
        const blogSummary = blog.blogHtml.replace(/<.*?>/g," ").substr(0, 400);
        //新增
        if (!this.state.blog.blogId) {
            console.log(blog);
            axios.post('/admin/blog',
                {
                    "userId": "1",
                    "blogTitle": blog.blogTitle,
                    "blogType": blog.blogType,
                    "blogContent": blog.blogContent,
                    "blogHtml": blog.blogHtml,
                    "blogSummary": blogSummary
                },
                {timeout: 1000}).then(res => {
                if (res.data === "1") {
                    Toast.info('发布成功！', 2000);
                }
            }).catch(err => {
                console.log(err);
                alert("操作失败！");
            });
        } else {
            axios.put('/admin/blog',
                {
                    "userId": "1",
                    "blogId":blog.blogId,
                    "blogUuid":blog.blogUuid,
                    "blogTitle": blog.blogTitle,
                    "blogType": blog.blogType,
                    "blogContent": blog.blogContent,
                    "blogHtml": blog.blogHtml,
                    "blogSummary": blogSummary
                },
                {timeout: 1000}).then(res => {
                if (res.data === "1") {
                    this.props.cb("SUCCESS");
                    Toast.info('发布成功！', 2000);
                }
            }).catch(err => {
                console.log(err);
                alert("操作失败！");
            });
        }
        this.setState({
            is_active: false
        });
    }

    selectAllType() {
        axios.get('/admin/type')
            .then(res => {
                const data = res.data;
                this.setState({
                    types: data
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        return (
            <div data-component="Bulma">
                <div className={this.state.is_active ? "modal is-active" : "modal"}>
                    <div className="modal-background"/>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">发表文章</p>
                            <button className="delete" aria-label="close" onClick={this.close.bind(this)}/>
                        </header>
                        <section className="modal-card-body">
                            <div className="field">
                                <label className="label">文章标题</label>
                                <div className="control">
                                    <input className="input" value={this.state.blog.blogTitle} type="text"
                                           id="blog_title" placeholder="请输入标题"/>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">请选择文章类型</label>
                                <div className="control">
                                    <div className="select">
                                        <select>
                                            {
                                                this.state.types.map((item) => {
                                                    if (item.typeId === this.state.blog.blogType) {
                                                        return (
                                                            <option key={item.typeId} value={item.typeName}>{item.typeName}</option>
                                                        );
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">文章简介</label>
                                <div className="control">
                                    <textarea className="textarea" placeholder="文章简介"/>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-right">
                            <button className="button is-success" onClick={this.sendBlog.bind(this)}>发表文章</button>
                            <button className="button" onClick={this.close.bind(this)}>取消</button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}