import './index.scss';
import {Component} from "react";
import {Link} from "react-router-dom";

class Home extends Component {
    constructor(props) {
        super(props);
        console.log("hello")
    }

    render() {
        return (
            <div className="App" data-component = "Home">
                <header id="header">
                    <div className="home">
                        <div className="logo">
                            <a href="/"><img src="/img/logoko.png" alt="" /></a>
                        </div>
                        <div className="c-share">
                            <input className="c-share__input" type="checkbox" id="checkbox"/>
                            <label className="c-share__toggler" htmlFor="checkbox">
                                <img className="c-tools" src={'/img/links.png'} alt=""/>
                            </label>
                            <ul className="c-share_options" data-title="实用工具">
                                <li><a href="https://www.iconfont.cn/" target="_blank" rel="noreferrer">阿里矢量图标库</a></li>
                                <li><a href="https://www.bejson.com/jsoneditoronline" target="_blank" rel="noreferrer">工具箱</a></li>
                                <li><a href="https://www.json.cn/" target="_blank" rel="noreferrer">JSON 在线解析</a></li>
                                <li><a href="http://www.bootcss.com/p/layoutit/" target="_blank" rel="noreferrer">Bootstrap可视化布局</a></li>
                                <li><a href="https://pixabay.com/zh/" target="_blank" rel="noreferrer">高清图片下载</a></li>
                            </ul>
                        </div>
                        <div className="my_blog">
                            <div>
                                <a href="/user/blog">学习笔记</a>
                                <div className="un-link"/>
                            </div>
                            <div>
                                <Link to="/person">个人主页</Link>
                                <div className="un-link"/>
                            </div>
                        </div>
                    </div>
                    <div className="main text-center">
                        <div className="head_img">
                            <img src="/img/favicon.ico" alt=""/>
                        </div>
                        <div id="head_text">
                            <p>生命不息 折腾不止</p>
                        </div>
                    </div>
                    <div className="text-center trigger">
                        <a className="scroll" href="#onePage" title="点击进入"><img src="/img/down.png" alt=""/></a>
                    </div>
                </header>
                <section>
                    <div className="container-fluid" id="onePage">
                        <div className="book-title">
                            <h2>GitBook 合集</h2>
                            <p>此模块将平时写的学习记录、技术博客进行分类整理及汇总，装订为GitBook，方便以后查阅</p>
                        </div>
                        <div className="book-box">
                            <div>
                                <img src="/img/interview.png" alt=""/>
                                <h3>面试题总结</h3>
                                <p>208道面试题，包含每个Java模块中最经典的面试题。</p>
                            </div>

                            <div>
                                <img src="/img/docsify.png" alt=""/>
                                <h3>Docsify 记事本</h3>
                                <p>一个神奇的文档网站生成器。Docsify 可以快速帮你生成文档网站。</p>
                            </div>

                            <div>
                                <img src="/img/spring.png" alt=""/>
                                <h3>Spring Framework 5</h3>
                                <p>Provides core support for dependency injection, transaction management, web apps,
                                    data access, messaging and more.</p>
                            </div>

                            <div>
                                <img src="/img/spring-boot.png" alt=""/>
                                <h3>Spring Boot 2.x</h3>
                                <p>Takes an opinionated view of building Spring applications and gets you up and running
                                    as quickly as possible.</p>
                            </div>

                            <div>
                                <img src="/img/spring.png" alt=""/>
                                <h3>Spring Cloud</h3>
                                <p>Provides a set of tools for common patterns in distributed systems. Useful for
                                    building and deploying microservices.</p>
                            </div>

                            <div>
                                <img src="/img/linux.png" alt=""/>
                                <h3>Linux</h3>
                                <p>一种自由和开放源码的类UNIX 操作系统</p>
                            </div>

                            <div>
                                <img src="/img/docker.png" alt=""/>
                                <h3>Docker</h3>
                                <p>Docker是轻量级的虚拟化技术，一个开源的应用容器引擎，让开发者可以打包他们的应用以及依赖包到一个可移植的容器中。</p>
                            </div>

                            <div>
                                <img src="/img/python.png" alt=""/>
                                <h3>Python</h3>
                                <p>Python 是一个高层次的结合了解释性、编译性、互动性和面向对象的脚本语言。</p>
                            </div>

                        </div>
                    </div>
                </section>
                <section>
                    <div className="works-list">
                        <div className="works-title">
                            {/*<div className="title">Spring Boot</div>*/}
                            <img className="img-box" src="/img/box_01.jpg" alt=""/>
                        </div>
                        <div className="works-title">
                            {/*<div className="title">Spring Cloud</div>*/}
                            <img className="img-box" src="/img/box_02.jpg" alt=""/>
                        </div>
                        <div className="works-title">
                            {/*<div className="title">气泡字体生成</div>*/}
                            <img className="img-box" src="/img/box_03.jpg" alt=""/>
                        </div>
                        <div className="works-title">
                            {/*<div className="title">Python</div>*/}
                            <img className="img-box" src="/img/box_04.jpg" alt=""/>
                        </div>
                        <div className="works-title">
                            {/*<div className="title">Linux</div>*/}
                            <img className="img-box" src="/img/box_05.jpg" alt=""/>
                        </div>
                    </div>
                </section>
                <footer id="endPage">
                    <div className="container">
                        <div className="link_box">
                            <div className="qq_link">
                                <img src="/img/qq.jpg" alt=""/>
                            </div>
                            <div className="weixin_link">
                                <img src="/img/weixin.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="user-links">
                            <ul>
                                <li className="qq">
                                    <img src="/img/qq.png" alt=""/>
                                    <div className="qq_link">
                                        <img src="/img/qq.jpg" alt=""/>
                                    </div>
                                </li>
                                <li className="weixin"><img src="/img/weixin.png" alt=""/></li>
                            </ul>
                        </div>
                        <div id="beian-01" className="beian_line beian_font">
                            <p >Copyright &copy; &nbsp;<a href="/">Mr.zhou</a>&nbsp;2019</p>
                            <p>豫ICP备案：<a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">豫ICP备19013911号-2</a></p>
                            <p><img className="beian_img" src="/img/beian.png" alt=""/>&nbsp;&nbsp;豫公网安备：<a target="_blank" rel="noreferrer"
                                                                                      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41018402000431">41018402000431号</a>
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Home;
