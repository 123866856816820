import {Component} from "react";
import './index.scss';
import './menu.scss';

class Person extends Component {
    constructor(props) {
        super(props);
        this.state = {menu_status: true};
        console.log("Person");
    }

    menuBtnActive = () => {
        if(this.state.menu_status){
            console.log(this.state.menu_status);
            this.setState({menu_status: false})
        }else {
            console.log(this.state.menu_status);
            this.setState({menu_status: true})
        }
    };


    render() {
        return (
            <div className={this.state.menu_status?'push-body':'push-body push-body-torigh'} data-component = "Person">
                <header id="main-navigation">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <nav id="menu-body" className={this.state.menu_status?'cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left':'cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left menu-open'}>
                                    <a href="/" className="push_nav_brand">Mr.zhou</a>
                                    <ul className="push_nav">
                                        <li><a className="active scroll" href="#home">封面</a></li>
                                        <li><a className="scroll" href="#resume">我的简历</a></li>
                                    </ul>
                                    <div className="icons">
                                        <ul>
                                            <li><button title="1746564597" className="fa fb"><img src='/img/qq-01.png' alt={'QQ'}/></button></li>
                                            <li><button title="ZLM_P09M0" className="fa tw"><img src="/img/weixin-01.png" alt={'WeiXin'}/></button></li>
                                            <li><button title="zlmbeiyan@163.com" className="fa fb"><img src="/img/email.png" alt={'email'}/></button></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-fluid">
                    <div id="menu-btn" onClick={this.menuBtnActive} className="main-button left">
                        <button  className={this.state.menu_status?'toggle-menu menu-left push-body':'toggle-menu menu-left push-body menu-active'}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
                <div id="home" className="row section">
                    <div className="info text-center">
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <div className="info_detail">
                                    <div className="zoom">
                                        <a href="/"><img src="/img/head.jpg" alt="alex"/></a>
                                        <div className="zoom-overlay"></div>
                                    </div>
                                    <h3>Mr.zhou</h3><br/>
                                    <p>一个喜欢折腾的小菜鸡</p>
                                    <div className="icons">
                                        <ul>
                                            <li><button title="1746564597" className="fa fb"><img src='/img/qq-01.png' alt={'QQ'}/></button></li>
                                            <li><button title="ZLM_P09M0" className="fa tw"><img src="/img/weixin-01.png" alt={'WeiXin'}/></button></li>
                                            <li><button title="zlmbeiyan@163.com" className="fa fb"><img src="/img/email.png" alt={'email'}/></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button">
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                        </div>
                        {/* <div className="button">
                            <a href="#contactme" data-target="contactme" className="btn active scroll">联络我</a>
                            <a href="#aboutme" data-target="aboutme" className="btn scroll">关于我</a>
                        </div> */}
                    </div>
                </div>
                {/*简历*/}
                {/*<div id="resume" className="row section">*/}
                    {/*<div className="container">*/}
                        {/*<h1 className='text-center' style={{marginBottom: 50+'px',color: '#333'}}>个人简历</h1>*/}
                        {/*<Resume/>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*联系我*/}
                <div id="contactme" className="row section">
                    <div className="container contact text-center">
                        <div >
                            <div className="heading">
                                <p>他日有缘，江湖再见</p>
                            </div>
                            <div className="icons">
                                <ul>
                                    <li><button title="1746564597" className="fa fb"><img src='/img/qq-01.png' alt={'QQ'}/></button></li>
                                    <li><button title="ZLM_P09M0" className="fa tw"><img src="/img/weixin-01.png" alt={'WeiXin'}/></button></li>
                                    <li><button title="zlmbeiyan@163.com" className="fa fb"><img src="/img/email.png" alt={'email'}/></button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Person;
